import React from "react";
import { Col, Row } from "react-bootstrap";
import CBBHImage from "../../Assets/CBBH.jpg"; // Import image

function CyberStack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={12} md={8} className="tech-icons"> {/* Ensures the image container is large enough */}
        <Col md={12} style={{ paddingBottom: 20, display: "flex", justifyContent: "center" }}>
          <img
            src={CBBHImage}  // Use imported image
            alt="certificate jpg"
            className="img-fluid"
            style={{
              width: "100%", // Make image width 100% of its parent container
              height: "auto", // Automatically adjust the height while keeping aspect ratio
            }}
          />
        </Col>
      </Col>
    </Row>
  );
}

export default CyberStack;

